import React, { useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';
import logo from '../images/1.png'; // Import your logo image




function ContactUs() {
  useEffect(() => {
    // Load Google Maps API script dynamically
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBue2sOGZFKRA-8i58mwvnAi6tToq8_Reg&libraries=places`;    script.async = true;
    script.onload = initMap;
    document.head.appendChild(script);

    return () => {
      // Clean up the script tag when the component is unmounted
      document.head.removeChild(script);
    };
  }, []);

  const form = useRef();

 
  const initMap = () => {
    // Initialize Google Map
    const mapOptions = {
      center: { lat: -31.8728, lng: 28.1551 }, // Set the initial center of the map (Replace with your preferred center)
      zoom: 10, // Set the initial zoom level
    };

    const map = new window.google.maps.Map(document.getElementById('map'), mapOptions);

    // Add markers for specific locations
    const locations = [
      { lat: -33.91840192331631, lng: 18.423073028835656, title: ' X-Cessive Occupational Health and Medical Solutions' },
      // Add more locations as needed
    ];

    locations.forEach(location => {
      new window.google.maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        map: map,
        title: location.title,
      });
    });
  };

  

  return (
    <div id='contact' >
      <h1>CONTACT US</h1>

      <div id='map' style={{ height: '600px', width: '80%' }}></div>

    
       

      
        {/* <form ref={form} onSubmit={sendEmail}>
          <input type="text" placeholder="Full Name" required name='user_name'/>
          <input type="email" placeholder="Type your E-mail"  name='user_email' required/>
          <input type="text" placeholder="Subject"  name='subject' required/>
          <textarea placeholder="Write here......." name="message"></textarea>
          <input type="submit" value='send'/>

         </form> */}
         <div class="contact-card-container">
    <div class="contact-card">
    <i class="fas fa-map-marker-alt"></i> 
        <h2>Address</h2>
        <p><b> 3rd Floor, no 2 Long Street, Cape Town CBD, 8000</b></p>
       
    </div>
    <div class="contact-card">
    <i class="fas fa-phone"></i>
        <h2>Contact details</h2>
        <p><b>Cell:  072 832 8801</b></p>
        <p><b>Alternative:  068 228 7911</b></p>
        <p><b>Email: <a href="mailto:xcessivehealth@medsolutions.com">xcessivehealth@medsolutions.com</a></b></p>

    </div>
    <div class="contact-card">
    <i class="fas fa-clock"></i>
        <h2>Operating hours</h2><br></br>
        <br></br>
        <h2><i>24 hours</i></h2>
      
        
        
    </div>
</div>

<footer className='footer'>
        <p>Copyright © 2024 All Rights Reserved</p>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <p style={{ marginRight: '10px' }}>Powered by </p>
          <a href="http://www.linkedin.com/in/mthunzi-siputuma-6b6a1721"> <img src={logo} alt="Siputuma Design Studios Logo" style={{ height: '80px' }} /></a>
        </div>
        {/*<a href="/privacy-policy">Privacy Policy</a> |
        <a href="/terms-and-conditions">Terms and Conditions</a>*/}
      </footer>
      </div>
    
  );
}

export default ContactUs;
