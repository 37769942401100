import React from 'react';
import aboutimage from '../images/image10.jpg';
import visionImage from '../images/image7.jpg';
import whyusImage from '../images/image8.jpg';

function About() {
  return (
    <div id='about' className='about-container'>
      <h1>ABOUT US</h1>
      <div className='about-image'>
        <img src={aboutimage} alt='About Us' />
      </div>
      <div className='about-text'>
        <p>
          X-Cessive Occupational Health and Medical Solutions is a premier provider of medical staffing and occupational health
          services, dedicated to delivering high quality, reliable solutions to healthcare facilities. Our specialization in providing
          locum nurses and doctors ensures that our clients receive uninterrupted, top notch medical care. In addition to staffing, we
          offer comprehensive occupational health and wellness services designed to enhance workplace safety and employee well-being.
        </p>

        <div className='section'>
          <div className='section-text'>
            <h2>Our Mission</h2>
            <ul>
              <li>Selecting the best candidates for every position.</li>
              <li>Providing continuous support with our consultants available 24/7.</li>
              <li>Upholding a commitment to quality and excellence in all our services.</li>
            </ul>
            
          </div>

          
        </div>

        <div className='section'>
          <img src={visionImage} alt='Vision' className='section-image' />
          <div className='section-text'>
            <h2>Vision</h2>
            <p>To be the premier provider of medical staffing and occupational health services, known for our unwavering commitment to quality, reliability, and client satisfaction.</p>
          </div>
        </div>

        <div className='section'>
          <div className='section-text'>
            <h2>Goals</h2>
            <ul>
              <li>To build and maintain long-lasting relationships with our clients.</li>
              <li>To consistently meet and exceed our clients' daily needs.</li>
              <li>To achieve high levels of client satisfaction through reliable and efficient service delivery.</li>
            </ul>
          </div>
        </div>

        <div className='section'>
          <img src={whyusImage} alt='Why Choose Us' className='section-image' />
          <div className='section-text'>
            <h2>Why Choose Us</h2>
            <ul>
              <li>Expertise: Our team comprises highly experienced professionals who are experts in their respective fields.</li>
              <li>24/7 Availability: We operate around the clock to ensure uninterrupted service.</li>
              <li>Quality Assurance: We prioritize quality in every aspect of our service delivery.</li>
              <li>Client-Centric Approach: Our clients' needs and satisfaction are at the forefront of our operations.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
