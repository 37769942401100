import React, { useState } from 'react';
import fimage1 from '../images/img15.jpg';
import fimage2 from '../images/image7.jpg';
import fimage3 from '../images/img12.jpg';
import fimage4 from '../images/img13.jpg';
import fimage5 from '../images/image6.jpg';
import fimage6 from '../images/image5.jpg';

function OurServices() {
  const services = [
    {
      title: "Medical Staffing",
      image: fimage1,
      description: "24/7 Availability: Our team is ready to respond to staffing needs at any time, day or night."
    },
    {
      title: "Locum Nurses and Doctors",
      image: fimage2,
      description: "We supply experienced and highly qualified locum professionals to fill temporary staffing gaps."
    },
    {
      title: "Customized Staffing Solutions",
      image: fimage3,
      description: "We work closely with clients to tailor our staffing services to their specific requirements."
    },
    {
      title: "Health Assessments",
      image: fimage4,
      description: "Regular health evaluations to monitor and maintain employee health."
    },
    {
      title: "Injury Prevention Programs",
      image: fimage5,
      description: "Strategies and training designed to reduce workplace injuries and promote safety."
    },
    {
      title: "Employee Wellness Programs",
      image: fimage6,
      description: "Initiatives focused on promoting healthy lifestyles and well-being among employees."
    },
    {
      title: "On-site Health Services",
      image: fimage1,
      description: "Providing on-site medical services to address immediate health concerns and promote overall health."
    }
  ];

  return (
    <div id='services'>
      <div className='service-header'>
        <h1>OUR SERVICES</h1>
      </div>
      <div className='a-container'>
        {services.map((service, index) => (
          <div key={index} className='service-card'>
            <img src={service.image} alt={service.title} />
            <h2>{service.title}</h2>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OurServices;
