import React from 'react';

function TermsAndConditions() {
  return (
    <div>
     
      {/* Your terms and conditions content */}
    </div>
  );
}

export default TermsAndConditions;
