import React from 'react';

function Header() {
  return (
    <div id='main'>
      <div className='header-heading'>

        <h1><span>X-Cessive Occupational</span></h1>
        <h1>Health and Medical
          Solutions
        </h1>
        <p className='details'>Our commitment to
          quality, integrity, and
          customer satisfaction is
          evident in every aspect of
          our operations.</p>
        {/* <br>
        </br><h1><span>Dr Sisipho Mayaya.</span></h1> */}
      </div>

    </div>
  )
}

export default Header;