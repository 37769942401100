import React from 'react';
import dentistImage1 from '../images/p.jfif';
import dentistImage2 from '../images/s.jfif';
import dentistImage3 from '../images/m.jfif';
import dentistImage4 from '../images/w.jfif';
import dentistImage5 from '../images/a.jfif';
import dentistImage6 from '../images/N.jfif';



function Ourdentist() {
  const teamMembers = [
    
    {
      name: "Mzikayise Kobo",
      image: dentistImage2,
      description: "EXCECUTIVE DIRECTOR"
    },
    {
      name: "Sikelela  Mdutyana",
      image: dentistImage1,
      description: "MANAGING DIRECTOR"
    },
  
    {
      name: "Doreen Khangale",
      image: dentistImage4,
      description: "CHIEF FINANCIAL OFFICER"
    },
    {
      name: " Khanyile Maxongo",
      image: dentistImage5,
      description: "CHIEF FINANCIAL OFFICER (VICE)"
    },
    {
      name: "Mthunzi Siputuma",
      image: dentistImage3,
      description: "CHIEF OPERATIONS OFFICER"}
  ];

  return (
    <div id='info'>
      <div className='team-header'>
        <h1>OUR TEAM</h1>
      </div>
      <div className='team-container'>
        {teamMembers.map((member, index) => (
          <div key={index} className='team-card'>
            <img src={member.image} alt={member.name} />
            <h2>{member.name}</h2>
            <p>{member.description}</p>
          </div>
        ))}
      </div>
      <div className='company-info'>
        <p>
          Our company is structured to provide seamless services
          with a focus on efficiency and client satisfaction. Our
          management team, whose profiles are featured on our
          website, consists of dedicated professionals committed to
          maintaining our high standards.
        </p>
      </div>
    </div>
  );
}

export default Ourdentist;
